import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Card from "./components/Card";
import Question from "./components/Question";
import { AskedQuestion } from "./components/AskedQuestion";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";

function AnimatedMeshGradient() {
  return (
    <div className="absoluteAnimation inset-0 justify-center">
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur"></div>
      <div className="bg-shape1 bg-primary-blue opacity-50 bg-blur"></div>
    </div>
  );
}

function App() {
  return (
    <div className="App overflow-x-hidden">
      <LoadingScreen />
      <AnimatedMeshGradient />
      <Header />
      <Banner />
      <Card />
      <Question />
      <AskedQuestion />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
