"use client";

import React, { useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Roadmap from "../components/RoadMap";
import BlurryCursor from "../components/BlurryCursor";
import Spline from "@splinetool/react-spline";
import OurTeam from "../components/OurTeam";
import "../App.css";
import AnimatedGradient from "../components/AnimatedGradient";
import LoadingScreen from "../components/LoadingScreen";

function AnimatedMeshGradient() {
  return (
    <div className="absoluteAnimation inset-0 justify-center">
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur"></div>
      <div className="bg-shape1 bg-primary-blue opacity-50 bg-blur"></div>
    </div>
  );
}

const ScrollParallaxText = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.5], [-100, 0]);
  const color = useTransform(scrollYProgress, [0, 1], ["#000", "#FFFFFF"]);

  return (
    <div ref={ref}>
      <motion.p
        style={{ opacity, x, color }}
        className="mt-4 text-[30px] w-full leading-[1.6]"
      >
        {children}
      </motion.p>
    </div>
  );
};

const SplitParagraph = ({ text }) => {
  const words = text.split(" ");
  const chunks = [];

  for (let i = 0; i < words.length; i += 10) {
    chunks.push(words.slice(i, i + 10).join(" "));
  }

  return (
    <div>
      {chunks.map((chunk, index) => (
        <ScrollParallaxText key={index}>{chunk}</ScrollParallaxText>
      ))}
    </div>
  );
};

const SiteIQ = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div className="App overflow-x-hidden mt-20">
        <LoadingScreen />
        <AnimatedMeshGradient />
        <Header />
        <div className="container h-full md:h-screen mx-auto flex flex-col md:flex-row items-center justify-between p-8">
          <div className="w-full md:w-1/2">
            <h1 className="mt-4 text-4xl leading-relaxed font-bold text-[#C8F95B] py-4">
              SiteIQ is an AI-powered platform designed to streamline
              construction site operations. With real-time monitoring, task
              automation, and sensor integration, SiteIQ helps managers ensure
              safety, quality, and efficiency on every project.
            </h1>
          </div>

          <div className="w-full md:w-1/2 relative z-50 flex items-end justify-center">
            <img className="siteiq-img" src="/siteiq.png" alt="" />
          </div>
        </div>
      </div>
      {/* Key Features Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between p-12 mb-10">
        <div className="w-full">
          <AnimatedGradient />
          <ScrollParallaxText>
            <h2 className="text-4xl font-bold mt-8">Key Features</h2>
          </ScrollParallaxText>
          <SplitParagraph text="Real-Time Task Management: Assign and track tasks across the site with instant updates for both workers and managers." />
          <SplitParagraph text="GPS Worker Tracking: Monitor worker locations in real time to improve safety and operational efficiency." />
          <SplitParagraph text="Concrete Quality Monitoring: Use embedded sensors to monitor concrete’s curing process in real-time, ensuring structural integrity." />
          <SplitParagraph text="AI-Powered Rebar Monitoring: Automatically verify rebar placement using AI and computer vision to ensure accuracy." />
          <SplitParagraph text="Comprehensive Analytics: Access real-time data on task completion, worker safety, and material quality." />
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between p-12 mb-10">
        <AnimatedMeshGradient />
        <div className="w-full">
          <ScrollParallaxText>
            <h2 className="text-4xl font-bold mt-8">Benefits</h2>
          </ScrollParallaxText>
          <SplitParagraph text="Enhanced Safety: Reduce accidents with GPS tracking and automated alerts for restricted areas." />
          <SplitParagraph text="Improved Quality Control: Real-time monitoring of concrete curing and rebar placement ensures compliance with building standards." />
          <SplitParagraph text="Efficiency: Automate tasks and streamline workflows, leading to faster decision-making and smoother project execution." />
          <SplitParagraph text="Data-Driven Decisions: Use real-time analytics to make informed choices and optimize resource allocation." />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SiteIQ;
