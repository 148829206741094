import React from "react";
import { Menus } from "../utils/helperFunctions";
import DesktopMenu from "./DesktopMenu";
import MobMenu from "./MobMenu";
import { motion, useScroll } from "framer-motion";
import { Link } from "react-router-dom"; 

export default function Header() {
  const { scrollY } = useScroll();
  const [logoSrc, setLogoSrc] = React.useState("/logo-purple.svg");
  const [logoHeight, setLogoHeight] = React.useState("65px");
  const [isVisible, setIsVisible] = React.useState(true); 

  React.useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest > 50) {
        setLogoSrc("/vb-purple.svg");
        setLogoHeight("35px"); 
        setIsVisible(false);
      } else {
        setLogoSrc("/logo-purple.svg");
        setLogoHeight("75px"); 
        setIsVisible(true); 
      }
    });
  }, [scrollY]);

  return (
    <div className="relative z-50">
      <header className="h-16 text-[15px] fixed inset-0 flex-center bg-[rgba(24,24,26,0.25)] ">
        <nav className=" px-3.5 flex-center-between items-center w-full max-w-7xl mx-auto">
          <div className="flex-center gap-x-3 z-[999] relative">
            <Link to="/"> 
              <motion.img
                className="w-[140px]"
                src={logoSrc}
                alt="VisionBuild Logo"
                style={{ height: logoHeight }} 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 3 }}
              />
            </Link>
          </div>

          <motion.ul
            className="gap-x-1 lg:flex-center hidden"
            initial={{ opacity: 1 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            {Menus.map((menu) => (
              <DesktopMenu menu={menu} key={menu.name} />
            ))}
          </motion.ul>

          <motion.div
            className="flex-center gap-x-5"
            initial={{ opacity: 1 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <button
              aria-label="sign-in"
              className="bg-[#C8F95B] text-black font-medium	 z-[999] relative px-3 py-1.5 shadow rounded-xl flex-center"
            >
              Request a Demo
            </button>
            <div className="lg:hidden">
              <MobMenu Menus={Menus} />
            </div>
          </motion.div>
        </nav>
      </header>
    </div>
  );
}
