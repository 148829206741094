import React from "react";
import Container from "./Container";

const OurTeam = () => {
  return (
    <Container>
      <div className="flex justify-center items-center flex-col my-10 relative z-10">
        <div className="flex justify-between items-center gap-x-8 gap-y-5 flex-col md:flex-row flex-wrap">
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <img src="/ugur-erkal.jpeg" alt="" />
              <div className="card-title-team flex flex-col">
                <h4>Uğur Erkal</h4>
                <h5>Co-Founder & CEO</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                With a strong background in strategic leadership and marketing,
                Uğur Erkal drives Visionbuild's growth and global expansion,
                ensuring the brand's strength across industries.
              </p>
            </div>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <img src="/izlen-geneci-emel.jpeg" alt="" />
              <div className="card-title-team flex flex-col">
                <h4>İzlen Geneci Emel</h4>
                <h5>Co-Founder, CTO</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                With a strong background in technology and AI development, İzlen
                oversees the creation of innovative solutions tailored to the
                construction industry.
              </p>
            </div>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <img src="/ahmet.jpeg" alt="" />
              <div className="card-title-team flex flex-col">
                <h4>Ahmet Bahadır Lülecioğlu</h4>
                <h5>Co-Founder</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                With over 20 years of experience in the construction sector,
                Ahmet manages our financial strategy, ensuring long-term
                sustainability and growth.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-x-8 gap-y-5 flex-col md:flex-row flex-wrap">
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <img src="/yildizdikme.png" alt="Yildiz Dikme" />
              <div className="card-title-team flex flex-col">
                <h4>Yıldız Dikme</h4>
                <h5>Consultant Frontend Developer</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                Yıldız oversees the development of animations, UI/UX design, and
                coding for VisionBuild, ensuring seamless integration of user
                experience and functionality
              </p>
            </div>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <img src="/ekin-ozcoban.jpeg" alt="" />
              <div className="card-title-team flex flex-col">
                <h4>Ekin Özçoban</h4>
                <h5>UX/UI Designer</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                As a passionate UX/UI designer, Ekin brings a balance of
                creativity and practicality to crafting intuitive, user-centered
                designs.
              </p>
            </div>
          </div>
          <div className="our-team-card">
            <div className="our-team-card-img flex items-center justify-between py-4">
              <div className="card-title-team flex flex-col">
                <h4>Carlo Lasorte</h4>
                <h5>Commercial Director</h5>
              </div>
            </div>
            <div className="card-parag">
              <p>
                With expertise in business development and sales, Carlo
                spearheads our commercial strategy, driving revenue growth and
                forging strong partnerships across various sectors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurTeam;
