"use client";

import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Roadmap from "../components/RoadMap";
import BlurryCursor from "../components/BlurryCursor";
import Spline from "@splinetool/react-spline";
import OurTeam from "../components/OurTeam";
import "../App.css";
import AnimatedGradient from "../components/AnimatedGradient";
import LoadingScreen from "../components/LoadingScreen";

function AnimatedMeshGradient() {
  return (
    <div className="absoluteAnimation inset-0 justify-center">
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur"></div>
      <div className="bg-shape1 bg-primary-blue opacity-50 bg-blur"></div>
    </div>
  );
}

const About = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="App overflow-x-hidden mt-20">
      <LoadingScreen />
      <AnimatedMeshGradient />
      <Header />
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-8">
        <div className="w-full md:w-1/2">
          <h1 className="text-[#C8F95B] uppercase font-bold text-3xl md:text-[64px]">
            About Us
          </h1>
          <p className="mt-4 text-lg py-4">
            We are a team of industry experts and technology enthusiasts
            dedicated to transforming construction site management through
            innovative AI solutions. With a diverse background in engineering,
            AI, and construction, our team combines decades of experience and a
            passion for technology to create groundbreaking solutions for the
            construction industry.
          </p>
        </div>

        <div className="w-[700px] h-[700px] md:w-1/2 splineBanner md:inline-block hidden">
          <Spline scene="https://prod.spline.design/1OIsklrAdJWvUj0A/scene.splinecode" />
        </div>
        <div className="w-[700px] h-[700px] md:w-1/2 splineBanner md:hidden inline-block">
          <Spline scene="https://prod.spline.design/6fprb5FmK32T7Fb5/scene.splinecode" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-evenly p-8 relative z-10">
        <AnimatedGradient />
        <div className="about-card">
          <h2>
            OUR <span>VISION</span>
          </h2>
          <img src="/shared-vision.png" alt="vision build" />
          <p>
            Our vision is to revolutionize the construction industry with
            AI-driven solutions that enhance safety, efficiency, and project
            management.
          </p>
        </div>
        <div className="about-card">
          <h2>
            OUR <span>MISSION</span>
          </h2>
          <img src="/target.png" alt="" />
          <p>
            We aim to provide cutting-edge technology that addresses the
            industry's most pressing challenges, ensuring safer and more
            efficient construction sites.
          </p>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-evenly px-8 my-10 our-story-section relative z-10">
        <AnimatedGradient />
        <h3>OUR STORY</h3>
        <p>
          We are a team of industry experts and technology enthusiasts dedicated
          to transforming construction site management through innovative AI
          solutions. With a diverse background in engineering, AI, and
          construction, our team combines decades of experience and a passion
          for technology to create groundbreaking solutions for the construction
          industry.
        </p>
        <p>
          Our journey began in Turkey, where our founders, in collaboration with
          key partners in the construction industry, identified significant
          safety challenges and inefficiencies in site management. Recognizing
          the strategic importance of Amsterdam's tech ecosystem, we established
          a base in the heart of the city. We proudly partnered with TNW (The
          Next Web), a Financial Times company, positioning us alongside some of
          the most prominent names in technology and innovation. This
          partnership has enabled us to thrive in a dynamic startup environment,
          leveraging TNW's extensive network, resources, and expertise.
        </p>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 my-10 what-do-section">
        <div className="w-full md:w-1/2">
          <h3>WHAT WE DO</h3>
          <p>
            From real-time safety monitoring to progress estimation, our
            solutions are designed to meet the unique needs of the construction
            industry. Our flagship product, the Safety Gear and Restricted Area
            Monitoring system, leverages existing security cameras to provide
            real-time detection of safety gear usage and restricted zone
            breaches. Upcoming products include Construction Tools and Machinery
            Tracking and Progress Estimation, further enhancing site management
            capabilities.
          </p>
        </div>
        <div className="w-full md:w-1/2 flex justify-end">
          <img src="/security-camera.png" alt="Vision Build" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 my-10 our-roadmap">
        <div className="flex flex-col justify-center text-center">
          <h3>OUR ROADMAP</h3>
          <p>
            Discover our product launch timeline and upcoming innovations. Each
            product is meticulously planned to address specific industry
            challenges and improve overall project outcomes.
          </p>
        </div>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row justify-between what-do-section relative">
        <AnimatedGradient />
        <div className="w-full md:w-1/2">
          <Roadmap />
        </div>
        <div className="w-full md:w-1/2">
          <div className="timeline">
            Launch of Safety Gear and Restricted Area Monitoring System
          </div>
          <div className="timeline">
            Beta release of Construction Tools and Machinery Tracking
          </div>
          <div className="timeline">
            Beta release of Progress Estimation tool with 360-degree camera
            integration
          </div>
        </div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between our-roadmap mb-10 relative z-10">
        <AnimatedGradient />
        <div className="flex flex-col justify-center text-center">
          <h3>OUR TEAM</h3>
          <p>
             Meet the talented professionals behind VisionBuild, including our
            founders, engineers, and industry advisors. Our team is composed of
            seasoned professionals in AI, software development, and construction
            management, all working together to deliver exceptional solutions.
          </p>
          <OurTeam />
        </div>
      </div>
      <Footer />
      <BlurryCursor isActive={isActive} />
    </div>
  );
};

export default About;
