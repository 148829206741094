import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

const LoadingScreen = () => {
  const [count, setCount] = useState(0);
  const loaderRef = useRef(null);
  const progressBarRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();

    const incrementCounter = () => {
      let currentCount = 0;
      const counterInterval = setInterval(() => {
        currentCount += 1;
        setCount(currentCount);

        gsap.to(progressBarRef.current, {
          width: `${(currentCount * 100) / 100}%`,
          ease: "power2.inOut",
          duration: 0.05,
        });

        if (currentCount === 100) {
          clearInterval(counterInterval);

          gsap.to(".loader", {
            scale: 40,
            duration: 1,
            ease: "power2.inOut",
            onComplete: () => {
              loaderRef.current.style.display = "none";
            },
          });
          gsap.to(".loading-screen", {
            opacity: 0,
            duration: 0.5,
            ease: "power1.inOut",
          });
        }
      }, 30);
    };

    incrementCounter();

    gsap.fromTo(
      progressBarRef.current,
      { width: 0 },
      { duration: 1.5, ease: "power2.inOut" }
    );
  }, []);

  return (
    <div className="loading-screen" ref={loaderRef}>
      <div className="loader">
        <div className="progress-bar" ref={progressBarRef}></div>
      </div>
      <div className="counter">
        <span className="count">{String(count).padStart(3, "0")}</span>
      </div>
    </div>
  );
};

export default LoadingScreen;
