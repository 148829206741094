import React from "react";

const AnimatedGradient = () => {
  return (
    <div className="absoluteAnimation inset-0 justify-center">
      <div className="bg-shape2 bg-[#C8F95B] opacity-50 bg-blur"></div>
      <div className="bg-shape1 bg-primary-blue opacity-50 bg-blur"></div>
    </div>
  );
};

export default AnimatedGradient;
