import { Camera } from "lucide-react";
import { Server } from "lucide-react";

export const Menus = [
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Products",
    subMenuHeading: ["Get started", "Programs", "Recent"],
    subMenu: [
      {
        name: "Safety Monitoring",
        path: "/safetymonitoring",
        icon: Camera,
      },
      {
        name: "SiteIQ",
        path: "/siteiq",
        icon: Server,
      },
    ],
    gridCols: 1,
  },
  {
    name: "Contact Us",
  },
];
