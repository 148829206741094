import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom"; // Link bileşeni eklendi

const Footer = () => {
  return (
    <footer className="text-white py-10 relative">
      {/* Üst taraftaki border */}
      <div className="absoluteAnimation top-0 left-0 w-full h-[1px] bg-[#C8F95B]" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row justify-between items-center">
        {/* Logo alanı */}
        <div className="lg:w-1/4 flex justify-center lg:justify-start mb-6 lg:mb-0">
          <Link to="/">
            <img
              src="logo-purple.svg"
              className="h-12 sm:h-16"
              alt="Flowbite React Logo"
            />
          </Link>
        </div>

        {/* Orta kısım: Adres ve Sosyal Medya */}
        <div className="lg:w-1/3 text-center lg:text-left">
          <p className="text-gray-300">
            Singel 542, 1017AZ Amsterdam/Netherland
          </p>
          <p className="text-gray-300 mt-2">+31612208696</p>

          {/* Sosyal Medya İkonları */}
          <div className="flex justify-center lg:justify-start space-x-4 mt-4">
            <a
              href="https://www.linkedin.com/company/visionbuildai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-white text-2xl hover:text-[#C8F95B]" />
            </a>
            <a
              href="https://www.instagram.com/visionbuild.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-white text-2xl hover:text-[#C8F95B]" />
            </a>
          </div>
        </div>

        {/* Alt kısım: Linkler */}
        <div className="lg:w-1/6 text-center lg:text-left">
          <h3 className="text-lg font-bold mb-4 mt-4">About Us</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/" className="text-gray-300 hover:text-[#C8F95B]">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-gray-300 hover:text-[#C8F95B]">
                About
              </Link>
            </li>
            <li>
              <Link to="/safetymonitoring" className="text-gray-300 hover:text-[#C8F95B]">
                SafetyMonitoring
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
