"use client";

import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spline from "@splinetool/react-spline";
import AnimatedGradient from "../components/AnimatedGradient";
import Container from "../components/Container";
import LoadingScreen from "../components/LoadingScreen";

const ScrollParallaxText = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [0, 1]);
  const x = useTransform(scrollYProgress, [0, 0.5], [-100, 0]);
  const color = useTransform(scrollYProgress, [0, 1], ["#000", "#FFFFFF"]);

  return (
    <div ref={ref}>
      <motion.p
        style={{ opacity, x, color }}
        className="mt-4 text-[30px] w-full leading-[1.6]"
      >
        {children}
      </motion.p>
    </div>
  );
};

const SplitParagraph = ({ text }) => {
  const words = text.split(" ");
  const chunks = [];

  for (let i = 0; i < words.length; i += 10) {
    chunks.push(words.slice(i, i + 10).join(" "));
  }

  return (
    <div>
      {chunks.map((chunk, index) => (
        <ScrollParallaxText key={index}>{chunk}</ScrollParallaxText>
      ))}
    </div>
  );
};

const SafetyMonitoring = () => {
  return (
    <>
      <LoadingScreen />
      <div className="video-container">
        <div className="overlay"></div>
        <Header />
        <motion.h1 className="text-[#C8F95B] uppercase font-bold text-xl md:text-[35px] leading-normal relative left-8 top-1/3 z-20">
          Safety Gear and Restricted Area Monitoring:Ensuring Safety and
          Compliance in Real-Time
        </motion.h1>
        <video
          src="/visionBuild.mp4"
          className="videoBanner"
          loop
          autoPlay
          muted
          playsInline
        ></video>
      </div>

      <div className="container relative mx-auto flex flex-col md:flex-row items-start justify-between p-12 mb-10">
        <div className="w-full">
          <AnimatedGradient />
          <ScrollParallaxText>
            <h2 className="text-4xl font-bold mt-8">
              Ensuring Compliance and Security
            </h2>
          </ScrollParallaxText>
          <SplitParagraph text="Our AI-powered solution provides comprehensive site safety and security by monitoring both safety gear compliance and access to restricted areas in real-time. " />
          <SplitParagraph text="Using existing security cameras, the system detects whether workers are wearing necessary gear such as helmets, vests, and gloves, while also identifying unauthorized access to sensitive zones like loading docks and high-risk machinery areas. " />
          <SplitParagraph text="Immediate alerts are sent to site managers via our web platform or mobile app, enabling proactive intervention to prevent accidents and security breaches, ensuring both safety and regulatory compliance." />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SafetyMonitoring;
